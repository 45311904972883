<template>
  <div class="container" v-loading="loading">
    <el-breadcrumb class="p_tb_10" style="width: 100%;" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/party/exemplaryVanguard',query:{id:$route.query.id} }">模范先锋</el-breadcrumb-item>
      <el-breadcrumb-item>优秀党员</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="box" v-for="item in contents.records" :key="item.id">
    <PartyMemberList :info="item" :pageId="$route.query.id" :from='2' :cid="$route.query.cid"></PartyMemberList>
    </div>
   <div class="box" v-show="contents.records.length%3!=0" v-for="(i,index) in 3-contents.records.length%3"
     :key="index"></div>
    <div class="paging">
       <el-pagination @current-change="handleCurrentChange" :page-size="parameter.size" :background="true"
         layout="total, prev, pager, next" :total="contents.total">
       </el-pagination>
    </div>
  </div>
</template>

<script>
  import {
    getPartyResources
  } from '@/api/party/index'
  import PartyMemberList  from "../components/PartyMemberList.vue"
  export default {
    name: 'index',
    data: () => {
      return {
        loading: false,
        contents: {
          records:[]
        },
        parameter: {
          contentIds: '',
          current: 1,
          size: 9,
          isExamine: 1
        },
      }
    },
    created() {
      this.parameter.contentIds = this.$route.query.cid
      this.getList();
    },
    components:{
      PartyMemberList
    },
    methods: {
      async getList() {
      this.loading = true;
        const {
          data
        } = await getPartyResources(this.parameter);
        this.contents = data;
        this.loading = false;
      },
      handleCurrentChange(page) {
        this.parameter.current = page;
        this.getList();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
     flex-wrap: wrap;
     padding: 0px 0 30px 0;
    .box{
      width:24.3%;
      margin-top: 10px;
    }
    .paging{
      
      width: 100%;
      text-align: center;
      margin-top: 30px;
    }
  }
</style>
